import React, { useEffect, useState } from "react"
import "../../components/home/styles.scss"
import ArrowTop from "../../assets/first/arrow-top.svg"
import Group from "../../assets/first/Group.svg"
import Rocket from "../../assets/first/rocket.svg"
import Spear from "../../assets/first/spear.svg"
import World from "../../assets/first/world.svg"
import EstoriMetric from "../../assets/fourth/EstoriMetric.svg"
import EstoriRectTree from "../../assets/fourth/EstoriRectaTree.svg"
import WorldR from "../../assets/fourth/worldTwo.svg"
import FrameOneR from "../../assets/fourth/Frame.svg"
import FrameTwoR from "../../assets/fourth/FrameOne.svg"
import GESTIONA from "../../assets/fourth/GESTIONA.svg"
import CONSTRUYE from "../../assets/fourth/CONSTRUYE.svg"
import DESCUBRE from "../../assets/fourth/DESCUBRE.svg"
import CONECTA from "../../assets/fourth/CONECTA.svg"
import RocketR from "../../assets/fourth/rocketTwo.svg"
import RH from "../../assets/first/RectangularH.svg"
import RHTwo from "../../assets/first/RectanHTwo.svg"
import ArrowTwo from "../../assets/fourth/Arrow-Down.svg"
import ArrowTopT from "../../assets/fourth/arrowTop.svg"

import clsx from "clsx"
const goToRegister = () => window.open("https://portal.estori.co/register/")
const SecondAreaHome = () => {
  const [activeP, setActiveP] = useState<string>("Construye")
  const [activeCarrousel, setactiveCarrousel] = useState<string>("Mercado")
  const [animation, setAnimation] = useState("");
  const [carrouselMark, setCarrouselMark] = useState<any>([
    {
      title: "Moda",
      img: "https://estori-assets-prod.s3.amazonaws.com/assets/website-flone-template-img.png",
      h: "Que tu personalidad brille con el look perfecto.", // Una tienda versátil y moderna de inspiración para miles
      p: "Crea tu tienda de forma instantánea y sin complicaciones. Ofrece tus colecciones y diseños con las ultimas tendencias de la moda y complace a tus seguidores.",
      color: "#B870FF",
      textColor: "#fcefed"
    },
    {
      title: "Mercado",
      img: "https://estori-assets-prod.s3.amazonaws.com/assets/website-borobazar-template-img.png",
      h: "Llegó la hora de modernizar la forma de hacer mercado.",
      p: "Crea tu tienda de venta al por menor en minutos, sube tu inventario y recibe pedidos muy fácilmente, justo como lo esperabas.",
      color: "#52E0AA",
      textColor: "#436d5d"
    },
    {
      title: "Tecnología",
      img: "https://estori-assets-prod.s3.amazonaws.com/assets/website-marfury-template-img.png",
      h: "Tu portafolio de productos y marcas de tecnología",
      p: "Ofrece los mejores productos electrónicos y tu amplia variedad de marcas a clientes que buscan lo último en vanguardia.",
      color: "#66A9FF",
    }
  ])
  const buildColumn = [
    {
      label: "Construye",
      img: <Group />,
    },
    {
      label: "Gestiona",
      img: <Spear />,
    },
    {
      label: "Conecta",
      img: <World />,
    },
    {
      label: "Descubre",
      img: <Rocket />,
    },
  ]
  const switchPagrah = (value: any) => {
    switch (value) {
      case "Construye":
        return "Crea una presencia digital sólida para tu marca y expande tu negocio profesionalmente."
      case "Gestiona":
        return "Maneja tu negocio desde donde estés. haz crecer tus ventas como nunca antes."
      case "Conecta":
        return "Integra tus canales de ventas, pagos, envios y mucho más. Todo desde un solo lugar."
      case "Descubre":
        return "Comprende a tus clientes y fidelizalos con experiencias de compras personalizadas e inteligentes."
      default:
        return "Crea una presencia digital sólida para tu marca y expande tu negocio profesionalmente."
    }
  }
  const switchImg = (value: any) => {
    switch (value) {
      case "Construye":
        return <CONSTRUYE />
      case "Gestiona":
        return <GESTIONA />
      case "Conecta":
        return <CONECTA />
      case "Descubre":
        return <DESCUBRE />
      default:
        return <DESCUBRE />
    }
  }

  const handlerLeft = () => {
    const c = carrouselMark.splice(1, 1)
    carrouselMark.reverse()
    setAnimation("left")

    setactiveCarrousel(carrouselMark[1].title)
    setCarrouselMark([...carrouselMark, c[0]])
  }
  const handlerRight = () => {
    const c = carrouselMark.splice(1, 1)
    carrouselMark.reverse()

    setactiveCarrousel(carrouselMark[0].title)
    setCarrouselMark([c[0], ...carrouselMark])
    setAnimation("right")
  }

  useEffect(() => {
    setTimeout(() => {
      setAnimation("")
    }, 500);

  }, [carrouselMark]);


  return (
    <section
      className="bg-white section_padding_0_100 second_section_background"
      id="descubre"
    >
      <div className="display-none-l">
        <RH />
      </div>

      <div className="p-5 p-4-l bg-other-color">
        <div className="w-100 col-12 display-none-l">
          <span className="color-orange">Tu propio canal. Bajo tu control.</span>
          <h3 className="title-wallet">
            Existen muchas razones para tener tu propio <span className="color-rose">eCommerce.</span>
          </h3>
          <p className="p-wallet">
            Tener un eCommerce es necesario si buscas expandir tu marca y fidelizar a tus clientes.
            Una sólida estrategia digital requiere un enfoque 360º. Conecta tu tienda y tus redes.
            Con más canales tendrás mayor alcance, pero el reto será automatizar tu trabajo. <br /> <br />
            <b> No te preocupes, para eso existimos.</b>
          </p>
        </div>
        <div className="row pt-5  pl-5 pr-5 padding-zero">
          <div className="col display-none-landing col-5">
            <span className="color-orange">Tu propio canal. Bajo tu control.</span>
            <h3 className="title-wallet">
              Existen muchas razones para tener tu propio <span className="color-rose">eCommerce.</span>
            </h3>
            <p className="p-wallet text-justify">
              Tener un eCommerce es necesario si buscas expandir tu marca y fidelizar a tus clientes.
              Una sólida estrategia digital requiere un enfoque 360º. Conecta tu tienda y tus redes.
              Con más canales tendrás mayor alcance, pero el reto será automatizar tu trabajo. <br /> <br />
              <b> No te preocupes, para eso existimos.</b>
            </p>
            <button className="btn btn-create-shop" onClick={goToRegister}>Empieza ahora</button>
          </div>

          <div className="estori-metrics col-7">
            <div className="row justify-content-center">
              <div className="col-5 text-center">
                <h3 className="h2-price">
                  <div className="display-none-landing">
                    <ArrowTop />
                  </div>
                  81%
                </h3>
                <p className="p-wallet ml-3">
                  Mayor percepción
                  <br /> de confianza
                </p>
              </div>
              <div className="col-5 text-center">
                <h3 className="h2-price">
                  <div className="display-none-landing">
                    <ArrowTop />
                  </div>
                  33%
                </h3>
                <p className="p-wallet ml-3">Más exposición de marca</p>
              </div>
              <div className="col-5 text-center">
                <h3 className="h2-price">
                  <div className="display-none-landing">
                    <ArrowTop />
                  </div>
                  21.7%
                </h3>
                <p className="p-wallet ml-3">Más clientes fidelizados</p>
              </div>
              <div className="col-5 text-center">
                <h3 className="h2-price">
                  <div className="display-none-landing">
                    <ArrowTop />
                  </div>
                  2.3x
                </h3>
                <p className="p-wallet ml-3">Más ventas probables</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="display-none-l">
        <RHTwo />
      </div>
      <div className="display-none-landing">
        <EstoriRectTree />
      </div>

      <div className="pl-4 pr-4 display-none-landing">
        <div className="row border-none justify-content-center">
          <div className="col-6 col-lg-6 col-md-6 col-sm-6 title-section-center text-center">
            <h3 className="mt-2">
              La plataforma All-in-One para tu negocio.
            </h3>
            <p className="p-wallet">
              Impulsa tu negocio con funcionalidades de vanguardia y capacidades de integración avanzadas
              para hacer crecer tu tráfico, ampliar tu alcance e impulsar tu conversión omnicanal.
            </p>
          </div>
        </div>
        <div className="row pl-5 pr-5 justify-content-center mt-4 ">
          <div className="col ">
            <div className="row justify-content-end">
              <div className="col-9">{switchImg(activeP)}</div>
            </div>
          </div>
          <div className="col">
            <div className="row ">
              <div className="col-10 mt-5 pt-3">
                {buildColumn.map((build: any, i: any) => (
                  <div
                    className={clsx(
                      "gestion-option mt-3",
                      activeP === build.label && "gestion-option-active "
                    )}
                    key={i}
                  >
                    <div className="d-flex w-100 align-items-center justify-content-between" onClick={() => setActiveP(build.label)}>
                      <div>
                        {build.img}
                        <span className="ml-3 title-gestion">
                          {build.label}
                        </span>
                      </div>
                      <div
                        className="cursor-pointer">
                        {activeP === build.label ? <ArrowTopT /> : <ArrowTwo />}
                      </div>
                    </div>
                    {activeP === build.label && (
                      <p className={clsx("p-wallet mt-2 ", activeP === build.label && "active-l")}>{switchPagrah(activeP)}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex mt-5 display-none-landing">
        <div className="rct-1"></div>
        <div className="rct-2"></div>
      </div>

      <div className="rounded-0 pl-4 pr-4  display-none-l ">
        <div className="pl-3 pr-3 pt-5">
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              {" "}
              <FrameTwoR />{" "}
              <span className="ml-3 title-gestion">Construye</span>
            </div>
            <p className="mt-2 p-wallet">
              Crea una presencia digital sólida para tu marca y expande tu
              negocio profesionalmente
            </p>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <FrameOneR /> <span className="ml-3 title-gestion">Gestiona</span>
            </div>
            <p className="mt-2 p-wallet">
              Maneja tu negocio desde donde estés. Haz crecer tus ventas como
              nunca antes
            </p>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              {" "}
              <WorldR />
              <span className="ml-3 title-gestion">Conecta</span>
            </div>
            <p className="mt-2 p-wallet">
              Integra tus canales de ventas, pagos, envios y mucho más. Todo
              desde un solo lugar
            </p>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <RocketR /> <span className="ml-3 title-gestion">Descubre</span>
            </div>
            <p className="mt-2 p-wallet">
              Comprende a tus clientes y fidelizalos con experiencias de compras
              personalizadas e inteligentes
            </p>
          </div>
        </div>
      </div>

      <div className="bg-pink rounded-0 pl-4 pr-4 bg-white-r d-flex flex-column">
        <div className="d-flex rct-margin display-none-landing h-10">
          <div className="rct-3"></div>
          <div className="rct-3"></div>
        </div>
        <div className="row border-none justify-content-center" style={{
          marginBottom: "5rem",
          height: "40%"
        }}>
          <div className="col-lg-6 col-md-6 col-sm-12 title-section-center text-center j-start">
            <div className="text-center">
              <span className="text-ls color-orange bg-ovale">Construye</span>
            </div>
            <h3 className="mt-2">Temas modernos y elegantes</h3>
            <p className="p-second-area ">
             {/*  Empieza tu eCommerce con nuestros atractivos temas de tienda de acuerdo a la categoría de tu negocio. <br />  */}
              Ofrece una experiencia fluida y profesional para que tus clientes disfruten tu marca, <br /> mientras que mantienes las riendas de tu negocio.
            </p>
          </div>
        </div>
        <div className="carousel-wrapper">
          {carrouselMark.map((item: any, i: any) => (
            <>
              {activeCarrousel === item.title ?
                <div className="h-m-50 carousel-card-main" key={i}>
                  <div className="arrow-left" style={{ zIndex: 1000 }} onClick={handlerLeft}>
                    <div className="arrow-a-left"></div>
                  </div>
                  <div
                    className={clsx("d-flex rectangular-carrousel-first justify-content-between")}
                    style={{ background: item.color }}
                  >
                    <div className="rectangular-carrousel-first-content">
                      <div className={clsx("mask-react", animation === "right" && ' fadeInRight animated-6', animation === "left" && ' fadeInLeft animated-6')}>
                        <img src={item.img} alt={item.title} />
                      </div>
                    </div>
                    <div className="overflow-hidden text-carrousel-first">
                      <span>{item.title}</span>
                      <h2 style={{ color: item.textColor }}>{item.h}</h2>
                      <p style={{ textAlign: 'justify' }}>{item.p}</p>
                    </div>
                  </div>
                  <div className="arrow-right" style={{ zIndex: 1000 }} onClick={handlerRight}>
                    <div className="arrow-a-right"></div>
                  </div>
                </div> :
                <div className="h-m-50 carousel-card" key={i} style={{
                  [i == 0 ? 'left' : 'right']: [i == 0 ? '240px' : '-40px']
                }}>
                  <div className={`d-flex flex-column rectangular-carrousel-second text-center`} style={{
                    background: item.color,
                    [i == 0 ? 'left' : 'right']: [i == 0 ? 0 : '315px']
                  }}>
                    <span>{item.title}</span>
                    <img style={{ width: '70%', position: 'absolute', [i == 0 ? 'left' : 'right']: '-40px' }} src={item.img} alt={item.title} />
                  </div>
                </div>
              }
            </>
          ))}

        </div>
        <div className="mt-2 text-center">
          <button className="btn btn-free" onClick={goToRegister}>
            Empieza ahora
          </button>
        </div>
      </div>
      <div className="display-none-landing">
        <EstoriMetric />
      </div>
    </section>
  )
}

export default SecondAreaHome
