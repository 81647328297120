import React, { useEffect, useState } from "react"
import Placeholder from "../../assets/fourth/placeholder.svg"
import Profile from "../../assets/fourth/imageProfile.svg"
import ProfileTwo from "../../assets/fourth/profileTwo.svg"
import PlaceholderTwo from "../../assets/fourth/placeholderTwo.svg"
import Right from "../../assets/fourth/arrow-right.svg"
import Left from "../../assets/fourth/arrow-left.svg"
import Integrations from "../../assets/fourth/Integraciones.svg"
import History from "../../assets/fourth/history.svg"
import IntegrationsTwo from "../../assets/fourth/integrationTwo.svg"
import IntegrationsH from "../../assets/fourth/IntegrationHistory.svg"
import World from "../../assets/fourth/worldTwo.svg"
import FrameOne from "../../assets/fourth/Frame.svg"
import FrameTwo from "../../assets/fourth/FrameOne.svg"
import Rocket from "../../assets/fourth/rocketTwo.svg"
import "../../components/home/styles.scss"

import clsx from "clsx"
const goToRegister = () => window.open("https://portal.estori.co/register/")

const FourAreaHome = () => {
  const oursExperiencie = [
    {
      id: 1,
      profile: <Profile />,
      description:
        "En mi anterior tienda en línea invertí mucho dinero y cada cambio requería más costos de agencia. Sin embargo, desde que me pasé a Estori puedo hacer los cambios yo mismo y todo es más fácil, intuitivo e incluso ofrecen un plan gratuito!",
      nameMerchant: "Omar Díaz",
      store: "dulceyseca.estori.co",
    },
    {
      id: 2,
      profile: <Profile />,
      description:
        "Con Estori mi negocio ha evolucionado y mi marca la reconocen cada vez más personas. Ya no solo utilizo redes sociales o el marketplace para vender, ahora mi tienda en línea es la vitrina perfecta para ofrecer la experiencia profesional de compra que buscaba.",
      nameMerchant: "Ana Angel",
      store: "tykhe.estori.co",      
    },
    {
      id: 3,
      profile: <Profile />,
      description:
        "Mi marca se encuentra en expansión y gracias a Estori he podido unificar mis canales de venta en un solo lugar sin perder ningún detalle, mi negocio funciona en automático y mis clientes han percibido cómo mi servicio ha mejorado.",
      nameMerchant: "Elkin Hamburger",
      store: "nutrivida.estori.co",
    },
  ]

  const [dataExperience, setDataExperience] = useState<any>()

  const [nextDat, setnextDat] = useState<any>(1)
  const [countData, setCountData] = useState(oursExperiencie.length)

  const handlerNext = (id: number) => {
    if (id < countData) {
      setnextDat(nextDat + 1)
    } else {
      setnextDat(1)
    }
  }
  const handlerPrev = (id: number) => {
    if (id > 1) {
      setnextDat(nextDat - 1)
    } else {
      setnextDat(3)
    }
  }
  useEffect(() => {
    const foundExperience = oursExperiencie.find(
      (element: any) => element.id === nextDat
    )
    setDataExperience(foundExperience)
  }, [nextDat])

  return (
    <section
      className="bg-white section_padding_0_100 bg-other-color"
      id="descubre"
    >
      <div className="display-none-l  ">
        <div className="row border-none">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 title-section-center ">
            <div className="text-center">
              <span className="text-ls color-gift bg-ovale">Conecta</span>
            </div>
            <h3 className="mt-4">
              Toma el control de la
              <br /> finanzas de tu negocio
            </h3>
            <p className="p-second-area mt-3">
              Sigue usando tus plataformas favoritas y descubre muchas más en
              nuestro marketplace de apps.
            </p>
          </div>
        </div>
      </div>
      <div className="display-none-landing">
        <Integrations />
      </div>
      <div className="display-none-l w-100 mt-2 ">
        <IntegrationsH className="w-100" />
      </div>

      <div className="pl-4 pr-4  ">
        <div className="row title border-none justify-content-center">
          <div className=" col-lg-4 col-md-4 col-sm-4 title-section-center text-center">
            <div className="w-100">
              <span className="offer-span">Descubre</span>
            </div>
            <p className="mt-4 p-wallet font-weight-l ">
              Captura, procesa y analiza datos relevantes de tu eCommerce para crear campañas de venta efectivas. Todo desde un solo lugar.
            </p>
          </div>
        </div>
        <div className="row justify-content-center mt-5  ">
          <div className="row  justify-content-between p-4 bg-pink w-70 ">
            <div className=" col  mr-1">
              <div className="d-flex ">
                <FrameTwo />
                <span className="ml-3 title-gestion ">Business Intelligence (BI)</span>
              </div>

              <p className="mt-2 p-wallet">
              Analiza la actividad de tu negocio en tiempo real y toma decisiones basadas en datos.
              </p>
            </div>
            <div className="col mr-1">
              <div className="d-flex ">
                <FrameOne />
                <span className="ml-3 title-gestion">Customer Data Platform (CDP)</span>
              </div>

              <p className="mt-2 p-wallet">
              Tu tienda, tus datos. Analiza y comprende patrones de compradores y visitantes en tu sitio.
              </p>
            </div>
            <div className=" col mr-1">
              <div className="d-flex ">
                <World />
                <span className="ml-3 title-gestion">Customer Relationship Manager (CRM)</span>
              </div>

              <p className="mt-2 p-wallet">
              Crea campañas dirigidas utilizando tus redes sociales, apps de mensajería y plataformas de marketing.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5 show-diagram">
          <IntegrationsTwo />
        </div>
      </div>
      <div className="row title border-none  justify-content-center faq-bg mt-5">
        <div className="col-lg-9 col-md-9 col-sm-9 title-section-center ml-5 pl-4 margin-zero margin-top-l ">
          <h3 className="mt-2 title-l">
            Inspirate con nuestros casos de éxito
          </h3>
        </div>
      </div>
      <div className="pl-5 pr-5 pt-4  overflow-hidden  faq-bg pb-4 carrousel-tablet">
        <div className="d-flex justify-content-center">
          <div className="case-study-slider">
            <div className="d-flex justify-content-end mt-4  pl-3 mb-3 overflow-hidden">
              {oursExperiencie.map((exp: any, i: any) => (
                <div
                  className={clsx(
                    "slider-carrousel mr-2",
                    exp.id === nextDat && " slider-carrousel-active"
                  )}
                  key={i}
                ></div>
              ))}
            </div>
            <div
              className="rectangular-carrousel"
              style={{ height: "430px" }}
            >
              <div className="col rectangular-carrousel-image">
                <div className="position-relative ">
                  <div
                    className={clsx(
                      "position-absolute placeholder-carrousel",
                      dataExperience &&
                        dataExperience.id === nextDat &&
                        "fadeInRight animated "
                    )}
                  >
                    <Placeholder />
                  </div>
                </div>
              </div>
              <div className="col overflow-hidden rectangular-carrousel-content">
                <div className={clsx("d-flex flex-column p-2")}>
                  <div
                    className={clsx(
                      dataExperience &&
                        dataExperience.id === nextDat &&
                        "fadeInRight animated "
                    )}
                  >
                    <div className={clsx("circle-rectangular")}>
                      {dataExperience && dataExperience.profile}
                    </div>
                    <p className={clsx("mt-3 p-carrousel")}>
                      {dataExperience && dataExperience.description}
                    </p>
                  </div>

                  <div className="d-flex w-100">
                    <div
                      className={clsx(
                        "d-flex flex-column w-100",
                        dataExperience &&
                          dataExperience.id === nextDat &&
                          "fadeInRight animated"
                      )}
                    >
                      <h3>{dataExperience && dataExperience.nameMerchant}</h3>
                      <span className={clsx("text-ls")}>
                        {dataExperience && dataExperience.store}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="position-relative h-100">
                      <div className="position-absolute buttons-position-next">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-carrousel mr-4"
                            onClick={() => handlerPrev(dataExperience.id)}
                          >
                            <Left />
                          </button>
                          <button
                            className="btn btn-carrousel"
                            onClick={() => handlerNext(dataExperience.id)}
                          >
                            <Right />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carrousel-mobile display-none-l  ">
        <div className="position-relative h-50-l">
          <div
            className={clsx(
              "position-absolute placeholder-carrousel",
              dataExperience &&
                dataExperience.id === nextDat &&
                "fadeInRight animated "
            )}
          >
            <PlaceholderTwo className="placeholder-mobile" />
          </div>
        </div>
        <div className="p-4 pb-5">
          <div
            className={clsx(
              dataExperience &&
                dataExperience.id === nextDat &&
                "fadeInRight animated"
            )}
          >
            <div className={clsx("circle-rectangular")}>
              <ProfileTwo />
            </div>
            <p className={clsx("mt-3 p-carrousel")}>
              {dataExperience && dataExperience.description}
            </p>
          </div>
          <div
            className={clsx(
              "d-flex flex-column w-100",
              dataExperience &&
                dataExperience.id === nextDat &&
                "fadeInRight animated"
            )}
          >
            <h3>{dataExperience && dataExperience.nameMerchant}</h3>
            <span className={clsx("text-ls")}>
              {dataExperience && dataExperience.store}
            </span>
          </div>
          <div className="d-flex justify-content-start mt-2  overflow-hidden">
            {oursExperiencie.map((exp: any, i: any) => (
              <div
                className={clsx(
                  "slider-carrousel mr-2",
                  exp.id === nextDat && " slider-carrousel-active"
                )}
                key={i}
                onClick={() => handlerNext(dataExperience.id)}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <div className="p-4 bg-white-l">
        <div className="d-flex  justify-content-center">
          <div className="row title border-none pl-5 pr-5 padding-zero margin-zero d-flex align-items-center">
            <div className="col  title-section-center  pt-5 pl-5 padding-zero margin-zero ">
              <h3 className="mt-2">
                Conecta tu marca con nuestra red de agentes para expandir tu posicionamiento.
              </h3>
              <p className="mt-3 p-second-area">
                {" "}
                Sé parte de una comunidad que te ayudará a aumentar el tráfico en tu tienda en línea y hacer crecer tus ventas. Empieza gratis hoy mismo!{" "}
              </p>
              <div className="mt-2">
                <button className="btn btn-free" onClick={goToRegister}>
                  Empieza ahora
                </button>
              </div>
            </div>
            <div className="latam-map col padding-zero margin-zero">
              <img src="https://estori-assets-prod.s3.amazonaws.com/assets/website-latam-map-img.png" alt="latam-img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FourAreaHome

function uuidv4() {
  throw new Error("Function not implemented.")
}
