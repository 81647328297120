// If you don't want to use TypeScript you can delete this file!
import React from "react"
// import "../css/home/index.css"
import FirstAreaHome from "../components/home/first-area"
import FourAreaHome from "../components/home/four-area"

import SecondAreaHome from "../components/home/second-area"
import ThirdAreaHome from "../components/home/third-area"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/home/index.css"
import "../css/home/responsive.css"
import { MetaData } from "../utils/globals"
import { Helmet } from "react-helmet"

const Index: React.FC<{}> = ({}) => (
  <>
    {/* SEO Start */}
    <Seo
      title={MetaData.HOME.META.title}
      description={MetaData.HOME.META.description}
      keywords={MetaData.HOME.META.keywords}
      author={MetaData.HOME.META.author}
      lang={MetaData.HOME.META.lang}
      meta={MetaData.HOME.META.meta}
      image={MetaData.HOME.META.image}
      portada={MetaData.HOME.META.portada}
    />
    {/* SEO End */}
    {/* Layout Start */}
    <Layout>
      {/* First Area Start */}
      <FirstAreaHome />
      {/* First Area End */}

      {/* Second Area Start */}
      <SecondAreaHome />
      {/* Second Area End */}

      {/* Third Area Start */}
      <ThirdAreaHome />
      {/* Third Area End */}

      <FourAreaHome />
      {/* Four Area End */}

    </Layout>
    {/* Layout End */}

    <Helmet>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/5117462.js"
      ></script>
    </Helmet>
  </>
)

export default Index
