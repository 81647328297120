import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from 'gatsby-image';
import Phone from "../../assets/third/phone.svg"

import SevenAreaHome from "./seven-area"
const goToRegister = () => window.open("https://portal.estori.co/register/")
const goToForm = () => window.open("https://pluriza.typeform.com/to/N3i8Kv")
export const query = graphql`
  query {
    Control: file(relativePath: { eq: "home/control.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    Girl: file(relativePath: { eq: "home/Girl.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
const ThirdAreaHome = () => {
  const data = useStaticQuery(query)
  return (
    <section className="bg-white section_padding_0_100 " id="beneficios">
      <div className="pl-4 pr-4 bg-b">
        <div className="row align-items-center  border-none">
          <div className=" col-lg-12 col-md-12 col-sm-12 title-section-center text-center margin-zero text-start-l mt-0">
            <div className="text-center">
              <span className="span-gestion color-gift">Gestiona</span>
            </div>
            <h1>
              Todo lo que necesitas para que tu negocio y tu<br/> estrategia de ventas sea la mejor
            </h1>        
          </div>
        </div>

        <SevenAreaHome />
        <div className="row mt-5">
          <div className="col align-self-center display-none-landing">
            <div className="d-flex justify-content-end">
              <div className=" control-third text-center ">
                <Img fixed={data.Control.childImageSharp.fixed} alt="contro third" />
              </div>
            </div>
          </div>

          <div className="col ">
            <div className="row border-none">
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 title-section-center ">
                <h3 className="mt-3 text-center">
                  Toma el control de las finanzas de tu negocio.
                  {/* <br />  */}
                </h3>
                <p className="p-second-area mt-3 text-center">
                  Controla tus ventas, gastos y utilidades.
                  <br /> Obtén reportes detallados desde un solo lugar.
                  <br /> Sin papeles ni fricción.
                </p>
                <div className="mt-3 text-center">
                  <button className="btn btn-free" onClick={goToRegister}>Empieza ahora</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="display-none-l mt-5 text-center">
          <Img fixed={data.Girl.childImageSharp.fixed} alt="Girl third" />
        </div>
        <div className="d-flex mt-5 pt-5 justify-content-center justify-content-none">
          <div className="rectangular-wallet ">
            <div className="row">
              <div className="col p-3">
                <span className="title-wallet">
                  Tus pagos rápidos y seguros sin costos ocultos.
                </span>
                <p className="p-wallet mt-4">
                  Una billetera segura y versátil con la que podrás recibir pagos de forma rápida. Olvídate de las complicaciones. <br />
                </p>
                <span className="wallet-wish-list"> Únete a nuestra lista de espera y sé de los primeros en usarla! <br />
                  <button className="btn btn-free" onClick={goToForm}>Quiero unirme</button>
                </span>
              </div>
              <div className="col">
                <div className="d-flex  justify-content-center">
                  <Phone />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ThirdAreaHome
