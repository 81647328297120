import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { gsap } from 'gsap';
import React, { useCallback, useEffect, useState } from 'react';
import { sendEmail } from '../../services/email.services';
import Loader from '../loader/index';
import './style.css';

export interface ModalProps {
  isShow: boolean;
  onClose: Function;
}

interface FieldContact {
  sharpening: string;
  nameComplete: string;
  email: string;
  numberContact: string;
  message: string;
}

interface ModalSendProps {
  isVisible: boolean;
}

const DefaultFieldContact = {
  email: '',
  message: '',
  nameComplete: '',
  numberContact: '',
  sharpening: '',
};

const defaultError = {
  error: false,
  messageError: '',
};

const ModalSend: React.SFC<ModalSendProps> = ({ isVisible }) => {
  const data = useStaticQuery(query);

  return (
    <>
      {isVisible && (
        <div className="finish-content" onClick={onclose as any}>
          <Img
            fluid={data.surface.childImageSharp.fluid}
            className="img-send-data"
            alt="Datos enviados correctamente"
          />
          <span className="text-send-data">
            ¡La solicitud se ha enviado de forma exitosa!
          </span>
          <span className="text-send-data">
            Nuestro equipo se estará comunicando contigo próximamente para
            vincular a tu negocio.
          </span>
        </div>
      )}
    </>
  );
};

const Modal: React.SFC<ModalProps> = ({ isShow, onClose }) => {
  const [field, setField] = useState<FieldContact>(DefaultFieldContact);
  const [loading, setLoading] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [error, setError] = useState({
    error: false,
    messageError: '',
  });

  const getAnimation = () => {
    const tl = gsap.timeline();
    //sequenced one-after-the-other
    tl.to('.content-modal', { duration: 1, x: -210 })
    //notice that there's no semicolon!
  };

  const registerUser = async () => {
    try {
      if (!validData()) {
        console.log("datos invalidos");
      } else {
        setLoading(true);
        const { status} =  await sendEmail(field);
        if (status === 201) {
          setField(DefaultFieldContact);
          setLoading(false);
          onCloseForm();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseForm = async () => {
    await gsap.to('.content-modal', {
      duration: 1,
      x: 210,
    });
    onClose();
    setIsFinish(false);
  };

  const handleChange = useCallback(
    (key: keyof FieldContact, value?: any) => {
      setField({
        ...field,
        [key]: value,
      });
    },
    [field]
  );

  const validData = () => {
    try {
      if (field.nameComplete.trim() == '' || field.nameComplete.length < 4) {
        setError({
          error: true,
          messageError: 'Ups, El nombre ingresado no parece ser correcto.',
        });
        return false;
      }

      if (
        !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9]+)\.([a-zA-Z]{2,5})$/.test(field.email)
      ) {
        setError({
          error: true,
          messageError: 'Correo no valido, por favor verifique los datos.',
        });
        return false;
      }

      if (!/^(\+57)?[3]{1}[0-2]{1}[0-9]{1}[0-9]{7}$/.test(field.numberContact)) {
        setError({
          error: true,
          messageError:
            'Número de telefono no valido, por favor verifique los datos.',
        });
        return false;
      }
      if (field.message === '') {
        field.message = 'No se ha escrito alguna nota'
      }

      return true;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAnimation();
  }, [isShow]);

  return (
    <>
      {isShow ? (
        <div className="background-modal">
          <div className="content-modal">
            <span
              className="modal-close-header"
              onClick={onCloseForm as any}
              style={{ cursor: 'pointer', zIndex: 3 }}
            >
              x
            </span>
            <div className="form-contact-modal">
              {isFinish ? (
                <ModalSend isVisible={isFinish} />
              ) : (
                <form>
                  {loading && (
                    <div className="content-loader">
                      <Loader visible={loading} />
                    </div>
                  )}
                  <span className="form-title">
                    Ingresa tus datos para que podamos comunicarnos contigo:
                  </span>
                  <br />
                  {error.error && (
                    <span className="form-title-err">{error.messageError}</span>
                  )}
                  <div className="form-group hg-f">
                    <label htmlFor="membershipControl">
                      Tipo de afiliación
                      <span style={{ color: 'tomato' }}> *</span>
                    </label>
                    <select
                      className="form-control hg-i"
                      id="membershipControl"
                      value={field.sharpening}
                      onChange={({ target: { value } }: any) => {
                        handleChange('sharpening', value)
                      }}
                    >
                      <option value="" disabled={true}>...</option>
                      <option value="ASOCIADO">Asociado</option>
                      <option value="AGENTE">Agente</option>
                    </select>
                  </div>

                  <div className="form-group hg-f">
                    <label htmlFor="NameUserControl">
                      Nombre completo:
                      <span style={{ color: 'tomato' }}> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control text-capitalize hg-i"
                      id="NameUserControl"
                      value={field.nameComplete}
                      autoComplete="off"
                      onChange={({ target: { value } }: any) => {
                        handleChange("nameComplete", value.toUpperCase())
                      }}
                      onFocus={() => {
                        setError({ error: false, messageError: "" })
                      }}
                    />
                  </div>

                  <div className="form-group hg-f">
                    <label htmlFor="emailControl">
                      Correo electrónico:
                      <span style={{ color: 'tomato' }}> *</span>
                    </label>
                    <input
                      type="email"
                      className="form-control text-lowercase hg-i"
                      id="emailControl"
                      value={field.email}
                      onChange={({ target: { value } }: any) => {
                        handleChange("email", value.toLowerCase())
                      }}
                      onFocus={() => setError(defaultError)}
                    />
                  </div>

                  <div className="form-group hg-f">
                    <label htmlFor="NumberUser">
                      Número de contacto:{' '}
                      <span style={{ color: 'tomato' }}> *</span>
                    </label>
                    <input
                      type="number"
                      className="form-control hg-i"
                      id="NumberUser"
                      value={field.numberContact}
                      onChange={({ target: { value } }: any) => {
                        handleChange("numberContact", value)
                      }}
                      onFocus={() => setError(defaultError)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="MessageControl">Mensaje:</label>
                    <textarea
                      className="form-control p-0"
                      id="MessageControl"
                      rows={2}
                      style={{ resize: 'none', fontSize: '12px' }}
                      value={field.message}
                      onChange={({ target: { value } }: any) => {
                        handleChange("message", value)
                      }}
                    />
                    <span className="footer-modal-text">
                      Al ingresar tu correo, aceptas nuestras Políticas de
                      Privacidad y que tus datos sean tratados conforme a la Ley
                      1581 de 2012.
                    </span>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm12 py-1 d-flex justify-content-center">
                    <button
                      type="button"
                      className={`btn btn-warning btn_send`}
                      onClick={registerUser}
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;

export const query = graphql`
  query {
    surface: file(relativePath: { eq: "shops/goals.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 250) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
