import { useState, useEffect } from "react";

interface InWindowSize {
  width: number;
  height: number;
}
export const  useWindowSize = () => {
 

  const [windowSize, setWindowSize] = useState<InWindowSize>({
    width: 0,
    height: 0,
  });
  useEffect(() => {

    const  handleResize = () => {

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
