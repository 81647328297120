import { graphql, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"
import { registerService } from "../../services/register.services"
import Estori from "../../assets/first/EstoriScreen.svg"
import Forms from "../../assets/first/Formas.svg"

import Partners from "../../components/home/partners"

import Modal from "../modal"

const FirstAreaHome = () => {
  const [openModal, setOpenModal] = useState(false)
  const [store, setStore] = useState<any>("");
  const [storeError, setStoreError] = useState<any>();

  const storeValidation = async (event: any) => {
    if (event.target.value.length > 20) setStoreError('Solo se permiten 20 carácteres.')
    else {
      setStoreError('')
      setStore(event.target.value.replace(/[^a-zA-Z0-9]/g, "").toLowerCase())
    }
  }

  const submitStore = async () => {
    const response = await registerService.getAvailableStoreId(store)
    if (response.data) setStoreError('Lo sentimos. Subdominio de tienda no disponible.')
    goToRegister()
  }

  const goToRegister = () => window.location.replace(`https://portal.estori.co/register/${store ? '?storeId=' + store : ''}`)

  return (
    <>
      <section className="wellcome_area clearfix overflow-hidden padding_for_large_screens" id="home">
        <div className="row first-section align-items-center">
          <div className="col col-lg-6 p-top-none first-section-content">
            <div className="headline">
              <h5 className="text-ls color-orange ">Lanza tu tienda en línea en minutos. #NoCode.</h5>
              <h1 className="first-h1 h1-title">
                La plataforma eCommerce para marcas reinventistas<br/>de Latam<span style={{ color: "#ff70a0" }}>.</span>
              </h1>
              <p className="p-content">
                Posiciona tu marca y brinda experiencias de compra personalizadas.  <br />
                <ul style={{fontSize: "smaller", margin: 0}}>
                  <li><span>&#10003;</span> Gestiona tus pedidos</li>
                  <li><span>&#10003;</span> Controla tu inventario</li>
                  <li><span>&#10003;</span> Automatiza tu marketing</li>
                  <li><span>&#10003;</span> Aumenta tus ventas</li>
                </ul>
              </p>
{/*               <div className="register-box">
                <div className="store_id-field">
                  {store === '' ?
                    <input
                      type="text"
                      className="input-shop-add"
                      name="name"
                      id="name"
                      placeholder="Nombre de tu marca"
                      aria-label=""
                      value={store}
                      onChange={e => setStore(e.target.value)}
                      autoFocus
                    /> :
                    <>
                      <span style={{ fontSize: "13px", width: "20%" }}>https://</span>
                      <input
                        className="box-input"
                        style={{ width: '65%', border: 'none', padding: 0, margin: 0, textAlign: 'center' }}
                        name="domain"
                        id="domain"
                        type="text"
                        placeholder="subdominio"
                        required
                        value={store}
                        onChange={storeValidation}
                        autoFocus
                      />
                      <span style={{ fontSize: "13px", width: "25%" }}>.estori.co</span>
                    </>
                  }
                </div>

                <span className="input-group-btn">
                  <button
                    className="btn btn-create-shop"
                    type="button"
                    aria-label=""
                    onClick={submitStore}
                  >
                    Empieza ahora
                  </button>
                </span>
              </div> */}
              <div className='display-inline'>
                <span style={{ fontSize: "10px", color: 'yellow' }}>{storeError}</span>
              </div>
              <button
                className="btn btn-create-shop2 w-100"
                type="button"
                aria-label=""
                onClick={goToRegister}
              >
                Empieza ahora
              </button>
              <p className="p-content" style={{fontSize: '10px', marginTop: '5px', color: '#ffffff'}}>
                * Prueba gratuita de 7 días. No se requiere tarjeta de crédito para empezar.
              </p>
            </div>
          </div>
          <div className="col  justify-content-end  display-none-landing estori-ref-main-image">
            <Estori />
          </div>
        </div>
        {/* Partners Start*/}
        {/*  <Partners /> */}
        {/* Partners End*/}
        <Modal isShow={openModal} onClose={() => setOpenModal(false)} />
        <div className="w-100 mt-2 display-none-landing">
          <Forms />
        </div>
      </section>
    </>
  )
}

export default FirstAreaHome
