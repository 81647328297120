import { configEnv } from '../config';
const URL = 'https://us-central1-mercabot-tori.cloudfunctions.net/sendEmail/'
const dataToken = {
  token:
    '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',
};

const handleResponse = (response: any) => {
  return response.text().then((text: any) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          const error = data || response.statusText;
          return Promise.reject(error);
      }
      return data;
  });
};

export const sendEmail = async (info: any) => {
  return fetch(`${configEnv.API_URL}/users/information`, {
    body: JSON.stringify(info),
    headers: {
      'Content-type': 'application/json',
    },
    method: 'POST',
  }).then(handleResponse)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        return err;
    });
};
