import clsx from "clsx"
import React, { useEffect, useState } from "react"
import Box from "../../assets/first/box.svg"
import Frame from "../../assets/first/Frame.svg"
import Ticket from "../../assets/first/Ticket.svg"
import Finances from "../../images/five-area/finances.gif"
import Orders from "../../images/five-area/orders.gif"
import Catalog from "../../images/five-area/catalog.gif"
import Clients from "../../images/five-area/clients.gif"
import Buy from "../../assets/first/Buy.svg"
import { graphql, useStaticQuery } from "gatsby"
import { useWindowSize } from "../../hooks/useWindowSize"

export const query = graphql`
query {
  Orders: file(relativePath: { eq: "five-area/orders.gif" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    Clients: file(relativePath: { eq: "five-area/product.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }    
    Catalog: file(relativePath: { eq: "five-area/catalog.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    Finance: file(relativePath: { eq: "five-area/finances.gif" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    },    
}
`

const SevenAreaHome = () => {
  const window = useWindowSize()
  const data = useStaticQuery(query)
  const [gestion, setGestion] = useState([
    "Gestiona tus pedidos",
    "Controla tu inventario",
    "Comprende a tus clientes",
    "Administra tus finanzas",
  ])
  const [activeItems, setActiveItems] = useState("Gestiona tus pedidos")
  const [activeItemsTwo, setActiveItemsTwo] = useState("Gestiona tus pedidos")
  const [pagraph, setPagraph] = useState<any>()

  const dataStrategy = [
    {
      id: 1,
      title: "Gestiona tus pedidos",
      titleTwo: "Gestiona tus pedidos",
      description:
        "Procesa los pedidos de tus clientes desde un solo lugar y de forma eficiente.",
      img: <Buy />,
    },
    {
      id: 2,
      title: "Controla tu inventario",
      titleTwo: "Controla tu inventario",
      description:
        "Monitorea y provisiona tu nivel de inventario de manera inteligente.",
      img: <Box />,
    },
    {
      id: 3,
      title: "Comprende a tus clientes",
      titleTwo: "Comprende a tus clientes",
      description:
        "Analiza y clasifica a tus clientes con base a su comportamiento de compra.",
      img: <Ticket />,
    },
    {
      id: 4,
      title: "Administra tus finanzas",
      titleTwo: "Administra tus finanzas",
      description:
        "Visualiza el rendimiento financiero de tu negocio en tiempo real.",
      img: <Frame />,
    },
  ]

  useEffect(() => {
    const control = () => {
      const deleteGestion: any = gestion.shift()

      setGestion([...gestion, deleteGestion])
      setActiveItems(deleteGestion)
    }
    const interval = setInterval(control, 6000)
    return () => clearInterval(interval)
  }, [gestion, activeItems])
  useEffect(() => {
    const activePa: any = dataStrategy.filter(
      (element: any) => element.title === activeItemsTwo
    )
    setPagraph(activePa[0].description)
  }, [activeItemsTwo])

  const handlerCarrousel = (title: any) => {
    setActiveItemsTwo(title)
  }

  const handlerImage = (value: any) => {
    switch (value) {
      case "Gestiona tus pedidos":
        return Orders

      case "Controla tu inventario":
        return Catalog

      case "Comprende a tus clientes":
        return Clients

      case "Administra tus finanzas":
        return Finances

      default:
        return data.Product.childImageSharp.fixed
    }
  }


  return (
    <>
      <div className="overflow-menu-horizontal display-none-l">
        {dataStrategy.map((element: any, i: any) => (
          <div
            className={clsx(
              activeItemsTwo === element.title ? "active-items-h" : "ml-2"
            )}
            onClick={() => handlerCarrousel(element.title)}
            key={i}
          >
            <div className="d-flex align-items-center item-mobile-carrousel">
              <div className="img-mobile-a">{element.img}</div>
              <span className="ml-2">{element.titleTwo}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="row mt-5 pl-4 pr-4">
        <div className="col display-none-landing">
          <div className="d-flex justify-content-end mr-2 align-items-center mt-5">
            <div className="d-flex flex-column justify-content-end w-60-l align-items-center">
              {dataStrategy.map((element: any, i: any) => (
                
                  <div
                    className={clsx(
                      "rectangular-items",
                      activeItems === element.title &&
                      "rectangular-active-items"
                    )}
                  >
                    <div className="d-flex">
                      <div className="w-25">
                        <div
                          className={clsx(
                            "d-flex align-items-center justify-content-center ",
                            activeItems === element.title && "items-active-img "
                          )}
                        >
                          {element.img}
                        </div>
                      </div>

                      <div className="d-flex flex-column  mt-1 ml-4">
                        <span
                          className={clsx(
                            "title-items-rectangular",
                            activeItems === element.title &&
                            "title-items-rectangular-active"
                          )}
                        >
                          {element.title}
                        </span>
                        <p
                          className={clsx(
                            "p-description",
                            activeItems === element.title &&
                            "p-description-active"
                          )}
                        >
                          {element.description}
                        </p>
                      </div>
                    </div>
                    {activeItems === element.title && (
                      <div className="progress-bar-item">
                        <div className="color-item-bar"></div>
                      </div>
                    )}
                  
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col back-rectangular">
          <div className="d-flex  justify-content-center">
            <div className="img-gestions-active">
              <img src={handlerImage(window.height >= 750 ? activeItems : activeItemsTwo)} alt="" />
              {/* <Img className="img-mobile-props" fixed={handlerImage(window.height >= 750 ? activeItems : activeItemsTwo)} alt="animated-ui" /> */}
            </div>
          </div>
        </div>
      </div>
      <p className="p-wallet display-none-l mt-2">{pagraph && pagraph}</p>
    </>
  )
}

export default SevenAreaHome
